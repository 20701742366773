<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <div class="input-container width600">
        <form autocomplete="off" name="username-form" v-on:submit.prevent>
          <input
            ref="username"
            v-model="username"
            v-on:keyup.enter="checkUserName"
            required
            type="text"
            class="input-standard-white validation fadeIn-2"
            id="username"
            placeholder="Your email"
          />
          <label for="username">Your email</label>
          <ErrorPop
            v-show="userNameError"
            :message="userNameError"
            :displayClose="true"
            @clearError="userNameError = null"
          ></ErrorPop>
        </form>
        <div v-show="show.loginRedirect" class="notification">
          If you have already registered with MyLastWill please
          <a
            @click="
              $router.push('/login').catch((error) => {
                console.log(error)
              })
            "
            href="#"
            >Login</a
          >
          to continue
        </div>
      </div>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:backLink="backTo"
        v-bind:center="false"
        @forwardClick="checkUserName"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import ErrorPop from '../../../../common/ui/ErrorPop'
import { functions } from '../../../../common/mixins/functions'
import { http } from '@/services'

export default {
  name: 'ProfileUsername',
  components: {
    NavQuestion,
    NavQuestionButtons,
    ErrorPop
  },
  mixins: [functions],
  mounted() {
    this.$emit('progress', '94%')
  },
  computed: {
    background() {
      return 'bg-personal-details-cookie'
    },
    isValidated() {
      return this.username && this.validEmail(this.username)
    },
    number() {
      if (
        this.personalDetails.partner_gender &&
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.charity
      )
        return '12'
      if (this.$store.getters.personalDetails.partner_full_name) return '10'
      return '8'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    heading() {
      return 'What is your email address?'
    },
    subHeading() {
      return (
        'This is where your completed Will is sent once it has been checked. We also use email to communicate ' +
        'with you during the will-writing process. This will also be used to create your account.'
      )
    },
    start() {
      return this.$router.history.current.name === 'WriteMyWillUsername'
    },
    forwardTo() {
      return '/write_my_will/password'
    },
    backTo() {
      if (this.personalDetails.marital_status === 'Single')
        return '/write_my_will/marital_status'
      else if (
        this.personalDetails.marital_status === 'Separated' ||
        this.personalDetails.partner
      )
        return '/write_my_will/partner_gender'
      return '/write_my_will/will_for'
    },
    usernameError() {
      return this.personalDetails.usernameError
    }
  },
  watch: {
    personalDetails: {
      immediate: true,
      deep: true,
      handler(value) {
        this.username = value.username
      }
    }
  },
  data() {
    return {
      username: null,
      userNameError: null,
      show: {
        loginRedirect: false
      }
    }
  },
  methods: {
    save() {
      this.$store.commit('personalDetails', {
        username: this.username
      })
    },
    clearError() {
      // this.$store.commit('personalDetails', { usernameError: '' })
      this.userNameError = null
    },
    checkUserName() {
      this.clearError()
      this.save()
      if (this.isValidated) {
        http
          .post('/wills/api/check_username', {
            username: this.username.trim()
          })
          .then((response) => {
            this.show.loginRedirect = false
            this.save()
            console.log(response.data)
            this.$router.push(this.forwardTo).catch((error) => {
              console.log(error)
            })
            this.clearError()
          })
          .catch((error) => {
            // this.$store.commit('personalDetails', { usernameError: error.response.data })
            this.save()
            if (error.response.data.message) {
              this.show.loginRedirect = true
              this.userNameError = error.response.data.message
            }
            if (
              error.response.data.dev_code === 'change_password_handover' &&
              error.response.data.user_hash
            ) {
              this.$router
                .push(`/account_handover/${error.response.data.user_hash}`)
                .catch((error) => {
                  console.log(error)
                })
            }
          })
      }
    }
  }
}
</script>

<style>
.notification {
  background-color: beige;
  padding: 30px;
  border-radius: 10px;
}
a {
  color: #e56781;
}
</style>
